import React, { } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
    Filter,
} from 'react-admin';
import ContractFilterInput from '../../components/ContractFilterInput'; // Reuse the contract filter input


/**
 * Filter component to select a contract in the UtilityUsageList.
 * The options are fetched from Supabase to get active contracts and room names.
 */
const UtilityUsageFilter: React.FC = (props) => {
    return (
        <Filter {...props}>
            <ContractFilterInput
                label="Room"
                source="contract_id"
                alwaysOn // Make the filter always visible
            />
        </Filter>
    );
};

/**
 * UtilityUsageList component displays a list of utility usages filtered by contract ID.
 */
const UtilityUsageList: React.FC = () => {
    return (
        <List filters={<UtilityUsageFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                    <ReferenceField source="room_id" reference="rooms">
                        <TextField source="room_name" />
                    </ReferenceField>
                </ReferenceField>
                <DateField source="month_year" label="Month/Year" />
                <NumberField source="electric_meter_reading" label="Electric Meter Reading" />
                {/* <NumberField source="water_meter_reading" label="Water Meter Reading" /> */}
                {/* <ImageField source="image_url" label="Proof Image" /> */}
                <DateField source="created_at" label="Created At" />
                {/* <DateField source="updated_at" label="Updated At" /> */}
            </Datagrid>
        </List>
    );
};

export default UtilityUsageList;
