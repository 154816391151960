// in authProvider.js
import { supabaseAuthProvider } from 'ra-supabase';
import { supabaseClient } from './supabase';

const getNameFromEmail = (email: string): string => {
    // Split the email at '@'
    const [namePart] = email.split('@');

    // Split the namePart by common delimiters like '.' or '_'
    const nameComponents = namePart.split(/[\._]/);

    // Capitalize the first letter of each component and join them into a full name
    const formattedName = nameComponents
        .map(component => component.charAt(0).toUpperCase() + component.slice(1).toLowerCase())
        .join(' ');

    return formattedName;
};

export const authProvider = supabaseAuthProvider(supabaseClient, {
    getIdentity: async user => {
        return {
            id: user.id,
            fullName: getNameFromEmail(user.email)
        };
        const { data, error } = await supabaseClient
            .from('userProfiles')
            .select('id, first_name, last_name')
            .match({ email: user.email })
            .single();

        if (!data || error) {
            throw new Error();
        }

        return {
            id: data.id,
            fullName: `${data.first_name} ${data.last_name}`,
        };
    },
});