import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    useDataProvider,
    useNotify,
    useRedirect,
    ReferenceInput,
} from 'react-admin';

const ContractCreate: React.FC = (props) => {
    const [serviceFees, setServiceFees] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        // Fetch service fees where fee_type = 'SERVICE'
        dataProvider.getList('service_fees', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: { fee_type: 'SERVICE' }, // Only fetch service fees of type 'SERVICE'
        }).then(({ data }) => {
            const defaultServiceFees = data.map((fee: any) => ({
                service_fee_id: fee.id,
                negotiated_fee: fee.default_fee,
                description: fee.description,
            }));
            setServiceFees(defaultServiceFees); // Prepopulate with default service fees
        }).catch(() => notify('Error fetching service fees', 'error'));
    }, [dataProvider, notify]);

    const handleSave = async (values: any) => {
        try {
            // Create any new customers in the form
            const customerPromises = values.contract_customers.map(async (customer: any) => {
                if (customer.new_customer) {
                    // Create new customer
                    const { data: newCustomer } = await dataProvider.create('customers', { data: customer });
                    return { ...customer, customer_id: newCustomer.id };
                }
                return customer;
            });

            // Wait for all new customers to be created
            const processedCustomers = await Promise.all(customerPromises);

            // Save the contract
            const contractData = {
                ...values,
                contract_service_fees: serviceFees,
                contract_customers: processedCustomers.map((cust: any) => ({
                    customer_id: cust.customer_id,
                    is_primary: cust.is_primary,
                })),
            };

            await dataProvider.create('contracts', { data: contractData });
            notify('Contract created successfully', 'info');
            redirect('/contracts');
        } catch (error) {
            notify('Error saving contract', 'error');
        }
    };

    return (
        <Create {...props}>
            <SimpleForm save={handleSave}>
                <TextInput source="contract_number" label="Contract Number" />
                <DateInput source="start_date" label="Start Date" />
                <DateInput source="end_date" label="End Date" />
                <NumberInput source="total_fee" label="Total Fee" />
                <SelectInput source="status" choices={[
                    { id: 'PENDING', name: 'Pending' },
                    { id: 'ACTIVE', name: 'Active' },
                    { id: 'TERMINATED', name: 'Terminated' }
                ]} />
                <TextInput source="description" label="Description" />

                {/* DataGrid for Service Fees (Prepopulated with default values) */}
                <ArrayInput source="contract_service_fees" label="Service Fees" initialValue={serviceFees}>
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="Service"
                            reference="service_fees"
                            source="service_fee_id"
                        >
                            <SelectInput optionText="service_name" />
                        </ReferenceInput>
                        <NumberInput source="negotiated_fee" label="Negotiated Fee" />
                        <TextInput source="description" label="Description" />
                    </SimpleFormIterator>
                </ArrayInput>

                {/* DataGrid for Customers */}
                <ArrayInput source="contract_customers" label="Customers">
                    <SimpleFormIterator>
                        <SelectInput
                            label="New or Existing"
                            source="new_customer"
                            choices={[
                                { id: true, name: 'New Customer' },
                                { id: false, name: 'Existing Customer' }
                            ]}
                            defaultValue={false}
                        />
                        {/* For new customer details */}
                        <TextInput source="customer_name" label="Customer Name" />
                        <TextInput source="phone_number" label="Phone Number" />
                        <TextInput source="email" label="Email" />
                        <TextInput source="bike_license_plate" label="License Plate" />
                        {/* Select existing customer */}
                        <ReferenceInput
                            label="Customer"
                            reference="customers"
                            source="customer_id"
                            allowEmpty
                        >
                            <SelectInput optionText="customer_name" />
                        </ReferenceInput>
                        <SelectInput
                            source="is_primary"
                            label="Primary Customer"
                            choices={[
                                { id: true, name: 'Yes' },
                                { id: false, name: 'No' }
                            ]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default ContractCreate;
