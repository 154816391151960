// in App.js
import * as React from "react";
import { Admin, CustomRoutes, Resource, ListGuesser } from 'react-admin';
import { LoginPage, SetPasswordPage, ForgotPasswordPage } from 'ra-supabase';
import { BrowserRouter, Route } from 'react-router-dom';
import { dataProvider } from './core/dataProvider';
import { authProvider } from './core/authProvider';
import pages from './pages';
export default function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={LoginPage}
        >
            <CustomRoutes noLayout>
                <Route
                    path={SetPasswordPage.path}
                    element={<SetPasswordPage />}
                />
                <Route
                    path={ForgotPasswordPage.path}
                    element={<ForgotPasswordPage />}
                />
            </CustomRoutes>
            {pages.map((page, i) => (
                <Resource key={i} {...page} />
            ))}
            {/* <Resource name="rooms" list={ListGuesser} />
            <Resource name="room_logs" list={ListGuesser} />
            <Resource name="houses" list={ListGuesser} />
            <Resource name="guests" list={ListGuesser} />
            <Resource name="guest_vehicles" list={ListGuesser} />
            <Resource name="customer_vehicles" list={ListGuesser} />
            <Resource name="items" list={ListGuesser} />
            <Resource name="storage_durations" list={ListGuesser} /> */}
        </Admin>
    );
} 