import { supabaseDataProvider } from 'ra-supabase';
import { supabaseClient } from './supabase';
import { DataProvider } from 'react-admin';
import { fetchUtils } from 'react-admin';

const baseDataProvider = supabaseDataProvider({
    instanceUrl: 'https://zeypmynsawlqtkgjcjjm.supabase.co',
    apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpleXBteW5zYXdscXRrZ2pjamptIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY0NzYwNTEsImV4cCI6MjA0MjA1MjA1MX0.jfeQseWJhoP_ovV7qNAV9rtppXcmmAo2M-t4HvcVmps',
    supabaseClient,
});


async function getAccessToken(): string {
    let a = await supabaseClient.auth.getSession();
    return a.data.session.access_token;
    // var a = supabaseClient.changedAccessToken || baseDataProvider.changedAccessToken;
    // return baseDataProvider.changedAccessToken;
}

// Extend the data provider with custom methods
export const dataProvider: DataProvider = {
    ...baseDataProvider,
    /**
     * Bulk create method for inserting multiple records into a Supabase table.
     * 
     * @param {string} resource - The name of the resource (Supabase table) to insert into.
     * @param {Object} params - The params object containing the data to insert.
     * @returns {Promise<{ data: any[] }>} - A promise that resolves to the created records.
     */
    bulkCreate: async (resource: string, params: { data: any[] }): Promise<{ data: any[] }> => {
        try {
            // Perform bulk insert using Supabase's insert method
            const { data, error } = await supabaseClient
                .from(resource)
                .insert(params.data)
                .select(); // Fetch and return the inserted records

            if (error) {
                throw new Error(`Error inserting records: ${error.message}`);
            }

            // Return the created records in a format react-admin expects
            return { data };
        } catch (err) {
            // Handle any errors and rethrow
            console.error('Bulk create error:', err);
            throw err;
        }
    },

    /**
     * Custom method to call the 'save_contract' RPC in Supabase
     * @param {string} resource - Resource name, not used here but follows react-admin pattern
     * @param {Object} params - Parameters for saving the contract
     */
    async saveContract(resource: string, params: { id: number, values: any, promotionDetails: any }) {
        const { id, values, promotionDetails } = params;
        if (values.contract_service_fees) {
            values.contract_service_fees = values.contract_service_fees.filter(f => !!f.negotiated_fee);
        }
        // Call the Supabase RPC function to save the contract
        const { data: v_contract_id, error } = await supabaseClient.rpc('save_contract', {
            p_start_date: values.start_date,
            p_end_date: values.end_date,
            p_description: values.description || ' ',
            p_status: values.status || 'ACTIVE',
            p_room_id: values.room_id,
            p_duration: values.contract_duration,
            p_contract_customers: values.contract_customers,
            p_contract_service_fees: values.contract_service_fees,
            p_electric_meter_reading: values.electric_meter_reading,
            p_water_meter_reading: values.water_meter_reading,
            p_image_url: values.image_url, // Proof of electric meter reading image URL
            p_promotion_details: promotionDetails, // Add promotion details if applicable
            p_contract_id: id,
            p_deposit_amount: values.deposit_amount, // Add deposit amount
            p_commission_fee: values.commission_fee, // Add commission fee
            p_payment_day: values.payment_day, // Add p_payment_day
        });

        if (error) {
            throw new Error(`Error saving contract: ${error.message}`);
        }

        return { data: { id: v_contract_id } }; // Return contract ID as the result
    },
    /**
     * Custom method to generate an invoice by contract ID and cycle.
     * @param {string} resource - Resource name (not used in this custom method).
     * @param {Object} params - Parameters including contractId and cycle.
     * @returns {Promise<{ data: any }>} - A promise that resolves to the response data.
     */
    async generateInvoice(resource: string, params: { contractId: number, cycle: string }): Promise<{ data: any }> {
        const { contractId, cycle } = params;

        const response = await fetchUtils.fetchJson(process.env.REACT_APP_API_URL + '/invoice/create/', {
            method: 'POST',
            body: JSON.stringify({
                contractId,
                cycle,
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                // 'Origin': 'devsoft.vn',
                'Authorization': `Bearer ${await getAccessToken()}`, // Use your API key
            }),
            mode: 'cors'
        });

        // Handle successful response and return the data
        return { data: response.json };
    },
};