
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const RoomLogList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="room_id" label="Room ID" />
            <TextField source="guest_id" label="Guest ID" />
            <TextField source="checkin_date" label="Check-in Date" />
            <TextField source="checkout_date" label="Check-out Date" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default RoomLogList;
