
import React from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps } from 'react-admin';

const ContractShow: React.FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="contract_number" label="Contract Number" />
            <TextField source="start_date" label="Start Date" />
            <TextField source="end_date" label="End Date" />
            <TextField source="total_fee" label="Total Fee" />
            <TextField source="status" label="Status" />
            <TextField source="description" label="Description" />
        </SimpleShowLayout>
    </Show>
);

export default ContractShow;
