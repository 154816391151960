
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const ServiceFeeList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="service_name" label="Service Name" />
            <TextField source="fee_type" label="Fee Type" />
            <TextField source="default_fee" label="Default Fee" />
            <TextField source="description" label="Description" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default ServiceFeeList;
