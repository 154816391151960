
import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, DateInput, SelectInput, EditProps } from 'react-admin';

const ContractEdit: React.FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="contract_number" label="Contract Number" />
            <DateInput source="start_date" label="Start Date" />
            <DateInput source="end_date" label="End Date" />
            <NumberInput source="total_fee" label="Total Fee" />
            <SelectInput source="status" choices={[
                { id: 'PENDING', name: 'Pending' },
                { id: 'ACTIVE', name: 'Active' },
                { id: 'TERMINATED', name: 'Terminated' }
            ]} />
            <TextInput source="description" label="Description" />
        </SimpleForm>
    </Edit>
);

export default ContractEdit;
