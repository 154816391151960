// in authProvider.js
import { supabaseAuthProvider } from 'ra-supabase';
import { supabaseClient } from './supabase';

const getNameFromEmail = (email: string): string => {
    // Split the email at '@'
    const [namePart] = email.split('@');

    // Split the namePart by common delimiters like '.' or '_'
    const nameComponents = namePart.split(/[\._]/);

    // Capitalize the first letter of each component and join them into a full name
    const formattedName = nameComponents
        .map(component => component.charAt(0).toUpperCase() + component.slice(1).toLowerCase())
        .join(' ');

    return formattedName;
};

// const { subscription: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//     if (session) {
//         const jwt = jwtDecode(session.access_token)
//         const userRole = jwt.user_role
//     }
// })

async function getUserRole(): string {
    let a = await supabaseClient.auth.getSession();
    return a.data.session.access_token;
    // var a = supabaseClient.changedAccessToken || baseDataProvider.changedAccessToken;
    // return baseDataProvider.changedAccessToken;
}


export const authProvider = supabaseAuthProvider(supabaseClient, {
    getIdentity: async user => {
        return {
            id: user.id,
            fullName: getNameFromEmail(user.email)
        };
        const { data, error } = await supabaseClient
            .from('userProfiles')
            .select('id, first_name, last_name')
            .match({ email: user.email })
            .single();

        if (!data || error) {
            throw new Error();
        }

        return {
            id: data.id,
            fullName: `${data.first_name} ${data.last_name}`,
        };
    },
    getPermissions: async () => {
        let a = await supabaseClient.auth.getSession();
        console.log(a);
        let { data, error } = await supabaseClient.rpc('get_role_permissions_resource');
        if (error) {
            console.error(error);
            return [];
        }
        return data.map(p => ({
            action: translate(p.action),
            resource: p.resource
        }))
        function translate(p) {
            if (p == '*') {
                return ["read", "create", "edit", "export", "delete"];
            } else if (p == "read") {
                return ["read", "export"];
            } else if (p == "write") {
                return ["read", "create", "edit", "export"];
            }
            return null;
        }
    }
    /*Promise.resolve([
    { action: ["read", "create", "edit", "export"], resource: "companies" },
    { action: ["read", "create", "edit"], resource: "people" },
    { action: ["read", "create", "edit", "export"], resource: "deals" },
    { action: ["read", "create"], resource: "comments" }, ,
    { action: ["read", "create"], resource: "tasks" },
    { action: ["write"], resource: "tasks.completed" },
])*/
});