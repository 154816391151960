import React from 'react';
import { supabaseClient as supabase } from '../../core/supabase'; // Supabase client
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, useNotify } from 'react-admin'; // Import the notify hook
/**
 * InvoiceViewButton component that shows a button for viewing the invoice
 * @param {Object} props - Component props
 * @param {string} props.publicInvoiceId - The ID of the invoice
 */
const InvoiceViewButton: React.FC<{ publicInvoiceId: string }> = ({ publicInvoiceId }) => {
    const notify = useNotify(); // Initialize notify hook
    const handleViewInvoice = async () => {
        try {
            const invoiceUrl = `https://tigon-invoice-viewer.devsoft.workers.dev/view/${publicInvoiceId}`;

            // Open the invoice viewer in a new tab
            window.open(invoiceUrl, '_blank');
        } catch (err) {
            console.error('Error handling view invoice:', err);
            notify('An unexpected error occurred while opening the invoice', { type: 'error' }); // Notify on unexpected errors        
        }
    };

    return (
        <Button
            label="View"
            onClick={handleViewInvoice}
        >
            <OpenInNewIcon />
        </Button>
    );
};

export default InvoiceViewButton;
