export type ContractServiceFee = {
    description: string | null;
    negotiated_fee: number;
    service_fee_id: number;
};

// constants.ts

// Enum to represent service fee types
export enum ServiceFeeId {
    Electricity = 41,      // Electricity fee ID
    Water = 42,            // Water fee ID
    // Internet = 4,         // Internet service fee ID
    Maintenance = 1,      // Maintenance service fee ID
    OccupancySurcharge = 40,
    BikeParkingLot = 2,

    // Add other service IDs here
}

// You can also export other hardcoded values
export const DefaultNegotiatedFee = 1000;
export const MaxDiscount = 50000;
