import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import PrintIcon from '@mui/icons-material/Print';
import dayjs from 'dayjs';
import { useNotify } from 'react-admin';
import numberToVietnameseText from './n2vi';
import { supabaseClient as supabase } from "../../core/supabase";
import { ServiceFeeId } from '../../ts-types/types';
/**
 * PrintContractButton Component
 * Triggers the print action for contract details, with an icon to improve UI.
 */
const PrintContractButton: React.FC = () => {
    const notify = useNotify();
    const { getValues } = useFormContext(); // Access form context to get values
    const [loading, setLoading] = useState(false); // Loading state

    // Handle print functionality
    const handlePrint = async () => {
        // console.log(supabase.changedAccessToken);
        const values = getValues(); // Get all form values
        const apiUrl = process.env.REACT_APP_API_URL + '/contract/print/'; // Replace with your API URL

        // Helper to format date as "ngày dd tháng mm năm yyyy"
        const formatDate = (date: string | Date) => {
            if (!date) {
                return null;
            }
            return dayjs(date).format('ngày DD [tháng] MM [năm] YYYY');
        };
        const primaryCustomer = values.contract_customers[0];
        const occupancySurcharge = values.contract_service_fees.find(f => f.service_fee_id === ServiceFeeId.OccupancySurcharge)?.negotiated_fee;
        const rentfee = values.contract_service_fees.find(f => f.fee_type === 'RENT').negotiated_fee; // must exist!!!
        // Construct print data
        const printData = {
            start_date: values.start_date ? formatDate(values.start_date) : "N/A",
            customer_name: primaryCustomer?.customer_name || "N/A",
            customer_dob: formatDate(primaryCustomer?.customer_dob) || "N/A",
            customer_id_num: primaryCustomer?.customer_identity_number || "N/A",
            customer_id_date: formatDate(primaryCustomer?.customer_id_issue_date) || "N/A",
            customer_address: primaryCustomer?.customer_address || "N/A",
            customer_phone: primaryCustomer?.phone_number || "N/A",
            house_address: `${values.room_details.house.address}, P. ${values.room_details.house.ward}, Q. ${values.room_details.house.district}, ${values.room_details.house.city}`,
            room_name: values.room_details.room_name,
            customer_count: values.contract_customers ? values.contract_customers.length : "N/A",
            room_addition_people_fee: occupancySurcharge || "Không nhận thêm người",
            contract_duration: values.contract_duration || "N/A",
            contract_start_date: values.start_date ? formatDate(values.start_date) : "N/A",
            contract_end_date: values.end_date ? formatDate(values.end_date) : "N/A",
            rent_fee: rentfee,
            rent_fee_text: numberToVietnameseText(rentfee),
            deposit_amount: values.deposit_amount || "N/A",
            deposit_amount_text: values.deposit_amount ? numberToVietnameseText(values.deposit_amount) : "N/A",
            electric_meter_reading: values.electric_meter_reading || "N/A",
            room_parking_lot: values.room_details.allowed_parking_lots || "N/A",
            room_free_parking_lot: values.room_details.available_parking_lots || "N/A",
            contract_payment_day: values.payment_day || "N/A",
        };
        // const requiredFields = ['start_date', 'customer_name', 'customer_dob', 'customer_id_num', 'customer_id_date', 'customer_address', 'customer_phone', 'house_address', 'room_name', 'customer_count', 'room_addition_people_fee', 'contract_duration', 'contract_start_date', 'contract_end_date', 'rent_fee', 'rent_fee_text', 'deposit_amount', 'deposit_amount_text', 'electric_meter_reading', 'room_parking_lot', 'room_free_parking_lot', 'contract_payment_day'];
        // const emptyFields = requiredFields.filter(f => !printData[f] || printData[f] == 'N/A');
        // if (emptyFields.length > 0)
        //     notify("Required fields: " + emptyFields.join(", "));


        try {
            setLoading(true); // Start loading
            // Call the print API
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + supabase.changedAccessToken
                },
                body: JSON.stringify(printData),

            });

            if (!response.ok) {
                throw new Error('Error generating PDF');
            }

            const pdfBlob = await response.blob();
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `contract_${values.id}.pdf`; // Example file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error during printing:', error.message);
        }
        finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            disabled={loading} // Disable button when loading
            startIcon={<PrintIcon />} // Add the Print icon here
        >
            {loading ? <CircularProgress size={24} /> : 'Print Contract'}
        </Button>
    );
};

export default PrintContractButton;
