
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const StorageDurationList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="contract_id" label="Contract ID" />
            <TextField source="checkin" label="Check-in" />
            <TextField source="checkout" label="Check-out" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default StorageDurationList;
