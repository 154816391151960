
import React from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps } from 'react-admin';

const RoomShow: React.FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="room_name" label="Room Name" />
            <TextField source="house_id" label="House ID" />
            <TextField source="guest_id" label="Guest ID" />
            <TextField source="area" label="Area" />
            <TextField source="rent_price" label="Rent Price" />
        </SimpleShowLayout>
    </Show>
);

export default RoomShow;
