
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const ContractList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="contract_number" label="Contract Number" />
            <TextField source="start_date" label="Start Date" />
            <TextField source="end_date" label="End Date" />
            <TextField source="total_fee" label="Total Fee" />
            <TextField source="status" label="Status" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default ContractList;
