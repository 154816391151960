
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const ItemList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" label="Item Name" />
            <TextField source="serial_number" label="Serial Number" />
            <TextField source="purchase_date" label="Purchase Date" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default ItemList;
