import React, { useState, useEffect } from 'react';
import { ArrayInput, SimpleFormIterator, SelectInput, AutocompleteInput, DateInput, TextInput, FormDataConsumer, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';
import CurrencyInput from '../../components/CurrencyInput';
import QRReaderButton from '../../components/QRReaderButton';

// Function to standardize name (Trim, remove double spaces, title case)
function standardizeName(name) {
    return toTitleCase(name
        .trim()
        .replace(/\s\s+/g, ' '));
    // .toLowerCase()
    // .replace(/\b\w/g, char => char.toUpperCase());
    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }
}

const warrantyOptions = [
    { id: 'no warranty', name: 'No Warranty' },
    { id: '6 months', name: '6 Months' },
    { id: '1 year', name: '1 Year' },
    { id: '2 years', name: '2 Years' },
    { id: '3 years', name: '3 Years' },
];

const calculateWarrantyExpiry = (purchaseDate: string, warrantyTerm: string): string | null => {
    if (!purchaseDate) return null;
    const date = new Date(purchaseDate);
    switch (warrantyTerm) {
        case '6 months':
            date.setMonth(date.getMonth() + 6);
            break;
        case '1 year':
            date.setFullYear(date.getFullYear() + 1);
            break;
        case '2 years':
            date.setFullYear(date.getFullYear() + 2);
            break;
        case '3 years':
            date.setFullYear(date.getFullYear() + 3);
            break;
        default:
            return null;
    }
    return date.toISOString().split("T")[0];  // Return as 'YYYY-MM-DD'
};

const AssetFormFields: React.FC = () => {
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [categories, setCategories] = useState<string[]>(['Tivi', 'Tủ Lạnh', 'Máy Giặt', 'Máy Lạnh', 'Khóa Cửa', 'Giường', 'Bàn', 'Ghế', 'Tủ Quần Áo']);
    const { setValue, control } = useFormContext();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const purchaseDate = useWatch({ name: 'purchase_date', control });
    const warrantyTerm = useWatch({ name: 'warranty_term', control });

    const [openDialog, setOpenDialog] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const [dialogType, setDialogType] = useState<"category" | "supplier">("category");
    const [newSupplier, setNewSupplier] = useState({
        supplier_name: "",
        contact_info: "",
        address: "",
    });

    useEffect(() => {
        // Fetch suppliers using the dataProvider
        dataProvider
            .getList('suppliers', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'supplier_name', order: 'ASC' },
                filter: {},
            })
            .then(({ data }) => {
                setSuppliers(data);
            })
            .catch((error) => {
                notify(`Error: ${error.message}`, { type: 'warning' });
            });
    }, [dataProvider, notify]);

    // Recalculate warranty expiry when purchase date or warranty term changes
    useEffect(() => {
        if (purchaseDate && warrantyTerm) {
            const warrantyExpiry = calculateWarrantyExpiry(purchaseDate, warrantyTerm);
            setValue('warranty_expiry', warrantyExpiry);
        }
    }, [purchaseDate, warrantyTerm, setValue]);

    // Handle supplier creation with contact_info and address using dataProvider
    const handleCreateSupplier = async () => {
        let { supplier_name, contact_info, address } = newSupplier;
        supplier_name = standardizeName(supplier_name);
        contact_info = standardizeName(contact_info);
        address = standardizeName(address);
        try {
            const { data } = await dataProvider.create('suppliers', {
                data: { supplier_name, contact_info, address },
            });
            const newSupplierData = { id: data.id, supplier_name: data.supplier_name };
            setSuppliers([...suppliers, newSupplierData]);
            setValue('supplier_id', newSupplierData.id);
            notify('New supplier created');
            setOpenDialog(false);
        } catch (error) {
            notify(`Error creating supplier: ${error.message}`, { type: 'warning' });
        }
    };

    // Handle category creation
    const handleCreateCategory = () => {
        setCategories([...categories, standardizeName(newCategory)]);
        setOpenDialog(false);
        notify('New category added');
    };

    // Open dialog for adding new supplier or category
    const openCreateDialog = (type: "category" | "supplier") => {
        setDialogType(type);
        setOpenDialog(true);
    };

    const readFromIdQR = ({ scopedFormData, formData }, result) => {
        scopedFormData.serial_number = result;
        setValue('serial_numbers', formData.serial_numbers);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Autocomplete input for selecting or creating a supplier */}
                    <AutocompleteInput
                        source="supplier_id"
                        choices={suppliers}
                        optionText="supplier_name"
                        optionValue="id"
                        label="Supplier"
                        fullWidth
                        onCreate={(newSupplierName) => {
                            const standardizedSupplierName = standardizeName(newSupplierName);  // Apply standardization
                            setNewSupplier({ ...newSupplier, supplier_name: standardizedSupplierName });
                            openCreateDialog("supplier");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Autocomplete input for selecting or creating the asset category */}
                    <AutocompleteInput
                        source="category_name"
                        choices={categories.map(category => ({ id: category, name: category }))}
                        optionText="name"
                        optionValue="name"
                        label="Category"
                        fullWidth
                        onCreate={(newCategoryName) => {
                            setNewCategory(newCategoryName);
                            openCreateDialog("category");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Date input for selecting the purchase date */}
                    <DateInput
                        source="purchase_date"
                        label="Purchase Date"
                        type="date"
                        fullWidth
                        defaultValue={new Date()}  // Default to today
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Date input for selecting the purchase date */}
                    <CurrencyInput
                        source="price"
                        label="Price"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Select input for choosing the warranty term */}
                    <SelectInput
                        source="warranty_term"
                        choices={warrantyOptions}
                        label="Warranty Term"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} xl={2}>
                    {/* Display the calculated warranty expiry */}
                    <DateInput source="warranty_expiry" label="Warranty Expiry (calculated)" fullWidth readOnly={true} />
                </Grid>

                <Grid item xs={12}>
                    {/* Array input to allow multiple serial numbers to be added */}
                    <ArrayInput source="serial_numbers" label="Serial Numbers">
                        <SimpleFormIterator disableClear={false} disableAdd={false} disableReordering={true} disableRemove={false}>
                            <Grid>
                                <FormDataConsumer>
                                    {(data) => (
                                        <QRReaderButton onScanSuccess={(result) => readFromIdQR(data, result)} />
                                    )}
                                </FormDataConsumer>
                                <TextInput source="serial_number" label="Serial Number" fullWidth />
                            </Grid>

                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>

            {/* Dialog for creating new supplier or category */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{dialogType === "category" ? "Add New Category" : "Add New Supplier"}</DialogTitle>
                <DialogContent>
                    {dialogType === "category" ? (
                        <TextField
                            autoFocus
                            margin="dense"
                            label="New Category"
                            fullWidth
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                        />
                    ) : (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Supplier Name"
                                fullWidth
                                value={newSupplier.supplier_name}
                                onChange={(e) => setNewSupplier({ ...newSupplier, supplier_name: e.target.value })}
                            />
                            <TextField
                                margin="dense"
                                label="Contact Info"
                                fullWidth
                                value={newSupplier.contact_info}
                                onChange={(e) => setNewSupplier({ ...newSupplier, contact_info: e.target.value })}
                            />
                            <TextField
                                margin="dense"
                                label="Address"
                                fullWidth
                                value={newSupplier.address}
                                onChange={(e) => setNewSupplier({ ...newSupplier, address: e.target.value })}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (dialogType === "category") {
                                handleCreateCategory();
                            } else {
                                handleCreateSupplier();
                            }
                        }}
                        color="primary"
                    >
                        Add
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssetFormFields;
