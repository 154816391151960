import HousePage from './houses';
import RoomPage from './rooms';
import RoomLogPage from './room_logs';
import GuestPage from './guests';
import GuestVehiclePage from './guest_vehicles';
import CustomerVehiclePage from './customer_vehicles';
import ItemPage from './items';
import StorageDurationPage from './storage_durations';
import ServiceFeePage from './service_fees';
import ContractPage from './contracts';

const resources = [
    HousePage,
    RoomPage,
    ServiceFeePage,
    ContractPage,
    RoomLogPage,
    GuestPage,
    GuestVehiclePage,
    CustomerVehiclePage,
    ItemPage,
    StorageDurationPage,
];

export default resources;
