import React from 'react';
import { Edit, SimpleForm, TextInput, EditProps } from 'react-admin';

const HouseEdit: React.FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="house_name" label="House Name" />
            <TextInput source="address" label="Address" />
            <TextInput source="ward" label="Ward" />
            <TextInput source="district" label="District" />
            <TextInput source="city" label="City" />
        </SimpleForm>
    </Edit>
);

export default HouseEdit;
