import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
    SelectInput,
    FunctionField,
    Filter,
} from 'react-admin';
import ContractFilterInput from '../../components/ContractFilterInput';

import InvoiceViewButton from './InvoiceViewButton'; // Import the button

/**
 * Filter component for InvoiceList, including the contract and status filters.
 */
const InvoiceFilter: React.FC = (props) => (
    <Filter {...props}>
        <ContractFilterInput label="Room" source="contract_id" alwaysOn />
        {/* Status Filter for invoices */}
        <SelectInput
            label="Status"
            source="status"
            choices={[
                { id: 'PENDING', name: 'Pending' },
                { id: 'PAID', name: 'Paid' },
                { id: 'OVERDUE', name: 'Overdue' },
                { id: 'CANCELLED', name: 'Cancelled' },
                { id: 'REFUNDED', name: 'Refunded' },
                { id: 'DISPUTED', name: 'Disputed' },
            ]}
            alwaysOn
        />
    </Filter>
);

/**
 * InvoiceList component displays a list of invoices filtered by contract ID and status.
 */
const InvoiceList: React.FC = () => {
    return (
        <List filters={<InvoiceFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="id" label="Invoice ID" />
                <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                    <ReferenceField source="room_id" reference="rooms">
                        <TextField source="room_name" />
                    </ReferenceField>
                </ReferenceField>
                <DateField source="invoice_date" label="Invoice Date" />
                <NumberField source="total_amount" label="Total Amount" />
                <TextField source="status" label="Status" />
                {/* Add a conditional button for pending invoices */}
                <FunctionField
                    label="Actions"
                    render={(record: any) =>
                        record.status === 'PENDING' ? (
                            <InvoiceViewButton publicInvoiceId={record.unique_id} />
                        ) : null
                    }
                />
                <DateField source="created_at" label="Created At" />
            </Datagrid>
        </List>
    );
};

export default InvoiceList;
