import HouseList from './HouseList';
import HouseCreate from './HouseCreate';
import HouseEdit from './HouseEdit';
import HouseShow from './HouseShow';
const HousePage = {
    name: 'houses',
    list: HouseList,
    edit: HouseEdit,
    create: HouseCreate,
    show: HouseShow
};

export default HousePage;