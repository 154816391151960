import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps, ReferenceField } from 'react-admin';
import CustomActions from './CustomActions'; // Import the CustomActions component
const RoomList: React.FC<ListProps> = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="house_id" reference="houses" label="House Name">
                    <TextField source="house_name" />
                </ReferenceField>
                <TextField source="room_name" label="Room Name" />
                <TextField source="area" label="Area" />
                <TextField source="rent_price" label="Rent Price" />
                <TextField source="status" label="Status" />
                <CustomActions /> {/* Use the CustomActions component */}
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default RoomList;
