// in App.js
import * as React from "react";
import { Admin, CustomRoutes, Resource, ListGuesser } from 'react-admin';
import { LoginPage, SetPasswordPage, ForgotPasswordPage } from 'ra-supabase';
import { BrowserRouter, Route } from 'react-router-dom';
import { dataProvider } from './core/dataProvider';
import { authProvider } from './core/authProvider';
import pages from './pages';
export default function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={LoginPage}
        >
            {permissions => [
                <CustomRoutes noLayout>
                    <Route
                        path={SetPasswordPage.path}
                        element={<SetPasswordPage />}
                    />
                    <Route
                        path={ForgotPasswordPage.path}
                        element={<ForgotPasswordPage />}
                    />
                </CustomRoutes>,
                ...pages.filter(page => !!permissions && permissions.some(permission => permission.resource === '*' || permission.resource === page.name)).map((page, i) => (<Resource key={i} {...page} />))
            ]}
        </Admin>
    );
} 