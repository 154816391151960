import HousePage from './houses';
import RoomPage from './rooms';
import RoomLogPage from './room_logs';
import GuestPage from './guests';
import GuestVehiclePage from './guest_vehicles';
import CustomerVehiclePage from './customer_vehicles';
import ItemPage from './items';
import StorageDurationPage from './storage_durations';
import ServiceFeePage from './service_fees';
import ContractPage from './contracts';
import AssetPage from './assets';
import UtilityUsagesPage from './utility_usages';
import InvoicesPage from './invoices';

const resources = [
    HousePage,
    RoomPage,
    ServiceFeePage,
    ContractPage,
    AssetPage,
    UtilityUsagesPage,
    InvoicesPage,
    // RoomLogPage,
    // GuestPage,
    // GuestVehiclePage,
    // CustomerVehiclePage,
    // StorageDurationPage,
];

export default resources;
