
import UtilityUsageList from './UtilityUsageList';
import UtilityUsageCreate from './UtilityUsageCreate';

const UtilityUsagesPage = {
    name: 'utility_usages',
    list: UtilityUsageList,
    create: UtilityUsageCreate,
    // edit: ContractEdit,
    // show: ContractShow,
};

export default UtilityUsagesPage;
