
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const GuestList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="guest_name" label="Guest Name" />
            <TextField source="birth_year" label="Birth Year" />
            <TextField source="phone_number" label="Phone Number" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default GuestList;
