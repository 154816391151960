import React, { useState } from 'react';
import { TextInput, TextInputProps } from 'react-admin';

// Helper function to format a number as currency
const formatCurrency = (value: number | string) => {
    if (!value) return '';
    const number = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat().format(value);
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(number);
};
// Function to format numbers with commas for better readability
const formatNumber = (value: number) => {
    return new Intl.NumberFormat().format(value);
};
// Helper function to remove non-numeric characters and return the raw number
const parseCurrency = (value: string) => {
    return value.replace(/[^0-9.-]+/g, '');
};


const CurrencyInput: React.FC<TextInputProps> = (props) => {
    const [formattedValue, setFormattedValue] = useState('');

    return (
        <TextInput
            {...props}
            parse={(v) => parseInt(parseCurrency(v), 10)}  // Parse the input value as an integer
            format={(v) => (v ? formatNumber(v) : '')}  // Format the displayed number
            value={formattedValue || props.value}
            placeholder="$0"
        />
    );
};

export default CurrencyInput;
